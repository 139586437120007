import PropTypes from 'prop-types';

export const NoticeIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='17'
      viewBox='0 0 14 17'>
      <path
        id='Vector'
        fill={fill}
        d='M3.111,1.545A1.551,1.551,0,0,1,4.667,0H9.333a1.551,1.551,0,0,1,1.556,1.545h.778a2.341,2.341,0,0,1,1.65.679A2.311,2.311,0,0,1,14,3.864V14.682a2.31,2.31,0,0,1-.683,1.639,2.341,2.341,0,0,1-1.65.679H2.333a2.341,2.341,0,0,1-1.65-.679A2.31,2.31,0,0,1,0,14.682V3.864A2.311,2.311,0,0,1,.683,2.224a2.341,2.341,0,0,1,1.65-.679Zm0,1.545H2.333a.78.78,0,0,0-.55.226.77.77,0,0,0-.228.546V14.682a.77.77,0,0,0,.228.546.78.78,0,0,0,.55.226h9.333a.78.78,0,0,0,.55-.226.77.77,0,0,0,.228-.546V3.864a.77.77,0,0,0-.228-.546.78.78,0,0,0-.55-.226h-.778A1.551,1.551,0,0,1,9.333,4.636H4.667A1.551,1.551,0,0,1,3.111,3.091Zm6.222,0H4.667V1.545H9.333Z'
        fillRule='evenodd'
      />
    </svg>
  );
};

NoticeIcon.propTypes = {
  fill: PropTypes.string,
};
